<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <label class="label-input">{{ $t("e-approve.label_select") }}</label>
        <v-select :items="todoStatus" outlined dense item-value="value" item-text="name" v-model="status"
          @change="searchFilterItem()"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <table class="table table-view" v-if="listEmployeeMyWork.length > 0">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.assignor") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.jobtitle") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.jobdescription") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.start") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.deadline") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.status") }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(item, index) in listEmployeeMyWork" :key="index" v-if="item.status == 'confirm' ||
                  item.status == 'updated' ||
                  item.status == 'success'
                  ">
                  <td>
                    {{ item.assignor.name }}
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.start_date }}</td>
                  <td>{{ item.deadline }}</td>
                  <td>
                    <span v-if="item.status == 'confirm'">
                      <v-chip small style="background-color: rgb(37, 184, 37) !important;color:white !important">
                        {{ $t("taskAllocation.status.received") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'updated'">
                      <v-chip small style="background-color: rgb(25, 16, 84) !important;color:white !important">
                        {{ $t("taskAllocation.status.updated") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'success'" style="color: green;">
                      {{ $t("taskAllocation.status.success") }}
                    </span>
                    <span v-if="item.status != 'success'">
                      <v-btn class="btn-create lfont btn ms-2 mr-2" color="blue-grey" small @click="modalUpdate(item.id)">
                        {{ $t("taskAllocation.table.workUpdate") }}</v-btn>
                      <v-btn class="btn-create lfont btn" color="success" small @click="success(item.id)">
                        {{ $t("taskAllocation.table.success") }}</v-btn>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
            <UpdateWork @success="fetchEmployeeMyWork" @close="dialogR = false" :id="id" :dialogR="dialogR" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UpdateWork from "./approve/UpdateWork.vue";
import Loading from "@/components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    UpdateWork,
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      status: "all",
      dialogR: false,
      id: "",
      isLoading: false,
      listEmployeeMyWork: [],
      todoStatus: [
        {
          id: 0,
          name: this.$t("taskAllocation.status.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("taskAllocation.status.received"),
          value: "confirm",
        },
        {
          id: 2,
          name: this.$t("taskAllocation.status.updated"),
          value: "updated",
        },
        {
          id: 3,
          name: this.$t("taskAllocation.status.success"),
          value: "success",
        },
      ],
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchEmployeeMyWork();
      // this.pagination.current_page = 1;
    },

    modalUpdate(id) {
      this.dialogR = true;
      this.id = id;
    },

    success(id) {
      this.$axios
        .get(`/employee/success/my/todo/list/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.fetchEmployeeMyWork();
            this.$router
              .push({
                name: "employee_task_allocation.mywork",
              })
              .catch(() => { });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    fetchEmployeeMyWork() {
      this.isLoading = true;
      const items = {
        status: this.status,
        start_date: "",
        end_date: "",
        // filter: this.filter,
      };
      this.$axios
        .post(`employee/list/my/todo/lists`, items)
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            this.isLoading = false;
            this.listEmployeeMyWork = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeMyWork.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeMyWork();
  },
};
</script>

<style lang="scss" scoped>
.table-body {
  tr {
    td {
      text-wrap: balance;
    }
  }
}

.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
